<template>
	<Metier name="expedition-terrestre" title="Expéditions Terrestres" :main-menu="[{title: 'Contremaître Transports Terrestres', missions: ['Assure les expéditions et réceptions quotidiennes', 'Assure la liaison avec les clients de la plateforme', 'Prépare et coordonne les travaux de maintenance relatifs aux gares routières', 'Veille au bon fonctionnement des installations d\'expédition dont il a la charge'], competences: ['Connaissance des logiciels de gestion des expéditions', 'Connaissance de la réglementation douanière, ferroviaire et administrative', 'Connaissance des règles de sécurité relatives à la manipulation des produits', 'Connaissance du matériel et des installations'], qualites: ['Leadership', 'Organisation', 'Sens relationnel', 'Esprit d\'analyse et de synthèse']}, {title: 'Opérateur', missions: ['Contrôle l\'expédition et la réception des produits', 'Participe au maintien du bon fonctionnement des installations d\'expédition', 'Contrôle le stock des produits dans les bacs et signale toute anomalie'], competences: ['Connaissance des règles de sécurité relatives à la manipulation des produits', 'Connaissance du matériel et des installations', 'Aptitude à l\'effort'], qualites: ['Esprit d\'équipe', 'Rigueur', 'Disponibilité']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
